html {
   scroll-behavior: smooth;
}
body {
   font-family: "Atyp Text", sans-serif;
   max-width: 100vw;
   overflow-x: hidden;
}
.container {
   width: 90%;
   margin: 0 auto;
   max-width: 1566px;
}
@font-face {
   font-family: "Atyp Text";
   src: url("../public/font/AtypDisplay-Light.woff2") format("woff2"),
      url("../public/font/AtypDisplay-Light.woff") format("woff"),
      url("../public/font/AtypText-Light.ttf") format("truetype");
   font-weight: 200; /* Light weight */
   font-style: normal;
}

@font-face {
   font-family: "Atyp Text";
   src: url("../public/font/AtypDisplay-Regular.woff2") format("woff2"),
      url("../public/font/AtypDisplay-Regular.woff") format("woff"),
      url("../public/font/AtypText-Regular.ttf") format("truetype");
   font-weight: 400; /* Normal weight */
   font-style: normal;
}

@font-face {
   font-family: "Atyp Text";
   src: url("../public/font/AtypDisplay-Semibold.woff2") format("woff2"),
      url("../public/font/AtypDisplay-Semibold.woff") format("woff"),
      url("../public/font/AtypText-Semibold.ttf") format("truetype");
   font-weight: 600; /* Semi-Bold weight */
   font-style: normal;
}

@font-face {
   font-family: "Atyp Text";
   src: url("../public/font/AtypDisplay-Bold.woff2") format("woff2"),
      url("../public/font/AtypDisplay-Bold.woff") format("woff"),
      url("../public/font/AtypText-Bold.ttf") format("truetype");
   font-weight: 700; /* Bold weight */
   font-style: normal;
}

/* menu */
#menu {
   padding-top: 2%;
   display: flex;
   width: 100vw;
   overflow: hidden;
}
#menu .menu {
   display: flex;
   flex-direction: row;
   align-items: center;
   width: 100%;
   justify-content: space-between;
   /* padding: 2% 0; */
}

.menu .nav {
   display: flex;
   flex-direction: row;
   width: 45%;
   justify-content: space-between;
}

#menu .menu a {
   font-weight: 400;
   align-items: center;
   text-decoration: none;
   color: #494947;
   display: flex;
   flex-wrap: nowrap;
   white-space: nowrap;
}

.menu #phone {
   color: #00458a;
}

/* Hamburger menu (toggle button) */
#menu .menu .hamburger {
   display: none;
   /* display: flex; */
   flex-direction: column;
   gap: 5px;
   cursor: pointer;
}

#menu .menu .hamburger div {
   width: 25px;
   height: 3px;
   background-color: #00458a;
   border-radius: 5px;
}

/* For mobile screens */
@media (max-width: 768px) {
   #menu .menu {
      flex-direction: row;
      padding-top: 5%;
      flex-wrap: wrap;
      align-items: center;
   }

   .menu .nav {
      width: 100%;
      flex-direction: column;
      /* gap: 10px; */
      margin-top: 20px;
      display: none; /* Hide the menu by default on small screens */
   }

   .menu .nav.open {
      display: flex;
   }

   #menu .menu .hamburger {
      display: flex;
   }

   .menu .nav a {
      font-size: 14px;
      padding: 8px 0;
   }

   .logo img {
      width: 180px;
   }

   /* Optional: Hide phone number on mobile */
   .menu #phone {
      display: none;
   }
}

/* Further mobile adjustments */
/* @media (max-width: 480px) {
   .menu .nav a {
      font-size: 12px;
   }

   .logo img {
      width: 100px;
   }
} */

/* about */
#about {
   padding-top: 5%;
}
.about {
   display: flex;
   flex-direction: column;
}
.about .text {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-bottom: 3.5%;
}
.about .text .inner {
   display: flex;
   flex-direction: column;
   width: 45%;
   justify-content: space-between;
}
#title {
   font-size: 48px;
   margin: 0;
   line-height: 57.6px;
   font-weight: 400;
   color: #494947;
   margin-bottom: 2%;
}
#title span {
   color: #00458a;
}
.about .text .inner p {
   font-size: 21px;
   line-height: 21.6px;
   font-weight: 400;
   color: #494947;
   margin: 0;
}
.about .text .links {
   display: flex;
   flex-direction: row;
   width: 30%;
   justify-content: space-between;
   align-items: end;
}
.about .text .links a {
   text-decoration: none;
   color: #494947;
   display: flex;
   flex-direction: row;
   gap: 15px;
   align-items: center;
}
.about .text .links img {
   width: 16px;
   aspect-ratio: 1;
}
#bg .img {
   width: 100%;
}
#bg .img img {
   width: 100%;
}
@media screen and (max-width: 768px) {
   #title {
      font-size: 28px;
      line-height: 31.25px;
   }
   .about .text {
      flex-direction: column;
   }
   .about .text .inner {
      width: 100%;
   }
   .about .text .inner p {
      font-size: 16px;
      line-height: 17.25px;
   }
   .about .text .links {
      width: 100%;
      margin-top: 5%;
   }
   #bg .img {
      height: 30vh;
   }
   #bg .img img {
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
   }
}

/* potential */
#potential {
   padding-top: 5%;
}
#potential .container {
   width: 56%;
}
.potential {
   text-align: center;
}
.potential p {
   font-size: 18px;
   color: #494947;
}

@media screen and (max-width: 768px) {
   #potential {
      padding-top: 10%;
   }
   #potential .container {
      width: 90%;
   }
   .potential {
      text-align: left;
   }
   .potential p {
      font-size: 16px;
   }
}

/* team */
#team {
   padding-top: 5%;
}
.team {
   width: 100%;
   border-radius: 50px;
   background-color: #f2f2f7;
   padding: 2% 0;
   display: flex;
}
.team .left {
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: space-between;
}
.team .who {
   display: flex;
   flex-direction: row;
   gap: 25px;
}
.team .who .lines {
   position: relative;
}
.team .who .lines .grey-line {
   background-color: #a0a0a0;
   position: absolute;
   width: 1px;
   height: 100%;
}
.team .who .lines .black-line {
   background-color: #494947;
   position: absolute;
   height: 50%;
   width: 1px;
   bottom: 0;
}
.team .who .guide {
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: space-between;
   width: 60%;
}
.team .who .guide p {
   color: #494947;
   font-weight: 400;
   font-size: 16px;
   display: flex;
   flex-wrap: nowrap;
   margin: 0;
}
.team .who .guide #active {
   color: #2d2d2c;
}
.team p {
   font-size: 36px;
   line-height: 43.6px;
   width: 75%;
   color: #494947;
}
.team .info {
   display: flex;
   flex-direction: row;
   gap: 2%;
}
.team a {
   background-color: #00458a;
   padding: 10px 15px;
   border-radius: 15px;
   color: #fffcff;
   text-decoration: none;
   font-weight: 400;
}
.team .container {
   display: flex;
   flex-direction: row;
}
@media screen and (max-width: 768px) {
   #team {
      padding-top: 10%;
   }
   #team .team .container {
      flex-wrap: wrap;
      gap: 5%;
   }
   .team .left {
      width: 100%;
      height: 50%;
   }
   .team .who {
      padding: 10% 0 0 0;
   }
   .team .left p {
      width: 100%;
      font-size: 22px;
      line-height: 26.4px;
   }
   .team .left .info a {
      font-size: 14px;
   }
   .team .right {
      width: 100%;
      height: auto;
      margin-bottom: -20%;
   }
   .team .right img {
      width: 100%;
      height: 60%;
      object-fit: cover;
      border-radius: 20px;
   }
}

/* guide */
#guide {
   padding-top: 5%;
}
.guide1 {
   width: 100%;
   border-radius: 50px;
   background-color: #f2f2f7;
   padding: 2% 0;
   display: flex;
}
.guide1 .left {
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: space-between;
}
.guide1 .who {
   display: flex;
   flex-direction: row;
   gap: 25px;
}
.guide1 .who .lines {
   position: relative;
}
.guide1 .who .lines .grey-line {
   background-color: #a0a0a0;
   position: absolute;
   width: 1px;
   height: 100%;
}
.guide1 .who .lines .black-line {
   background-color: #494947;
   position: absolute;
   height: 50%;
   width: 1px;
}
.guide1 .who .guide {
   display: flex;
   flex-direction: column;
   height: 100%;
   justify-content: space-between;
   width: 60%;
}
.guide1 .who .guide p {
   color: #494947;
   font-weight: 400;
   font-size: 16px;
   display: flex;
   flex-wrap: nowrap;
   margin: 0;
}
.guide1 .who .guide #active {
   color: #2d2d2c;
}
.guide1 p {
   font-size: 36px;
   line-height: 43.6px;
   width: 75%;
   color: #494947;
   font-weight: 400;
}
.guide1 .info {
   display: flex;
   flex-direction: row;
   gap: 2%;
}
.guide1 a {
   background-color: #00458a;
   padding: 10px 15px;
   border-radius: 15px;
   color: #fffcff;
   text-decoration: none;
   font-weight: 400;
}
.guide1 .container {
   display: flex;
   flex-direction: row;
}

@media screen and (max-width: 768px) {
   #guide {
      padding-top: 10%;
   }
   #guide .guide1 .container {
      flex-wrap: wrap;
      gap: 5%;
   }
   .guide1 .left {
      width: 100%;
      height: fit-content;
   }
   .guide1 .who {
      padding: 10% 0;
   }
   .guide1 .left p {
      width: 100%;
      font-size: 22px;
      line-height: 26.4px;
      margin-bottom: 5%;
   }
   .guide1 .left .info a {
      font-size: 14px;
   }
   .guide1 .right {
      width: 100%;
      height: auto;
      margin-bottom: -20%;
      margin-top: 5%;
   }
   .guide1 .right img {
      width: 100%;
      height: 60%;
      object-fit: cover;
      object-position: top;
      border-radius: 20px;
   }
}

/* commitet */
#commitet {
   padding-top: 5%;
}
.commitet {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   gap: 5%;
}
.commitet #title {
   width: 55%;
}
.commitet p {
   width: 50%;
   font-size: 18px;
}
@media screen and (max-width: 768px) {
   #commitet {
      padding-top: 10%;
   }
   .commitet {
      flex-direction: column;
   }
   .commitet #title {
      width: 100%;
   }
   .commitet p {
      width: 100%;
      font-size: 16px;
      line-height: 18.25px;
      color: #494947;
   }
}

/* footer */
#footer {
   margin-top: 5%;
   background-color: #00458a;
   padding: 3% 0;
}
#footer .footer {
   display: flex;
   flex-direction: column;
}
.footer .top {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   border-bottom: 1px solid #fffcff;
   padding-bottom: 3%;
}
.footer .top .info {
   display: flex;
   flex-direction: row;
   gap: 10%;
   width: 60%;
   justify-content: end;
}
.footer .top .info p {
   display: flex;
   flex-wrap: nowrap;
   color: #fffcff;
   margin: 0;
}
.footer .bottom {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-top: 3%;
}
.footer .bottom .links {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   gap: 10%;
}
.footer .bottom .links a {
   text-decoration: none;
   color: #fffcff;
   font-size: 16px;
   margin: 0;
}
.footer .bottom p {
   font-size: 16px;
   color: #fffcff;
   margin: 0;
}
@media screen and (max-width: 768px) {
   #footer {
      margin-top: 10%;
   }
   #footer .footer {
      display: flex;
      flex-direction: column;
   }
   .footer .top {
      flex-direction: column;
      justify-content: space-between;
      height: 30vh;
      padding-bottom: 10%;
   }
   .footer .top .info {
      display: flex;
      flex-direction: column;
      gap: 10%;
      width: 100%;
      height: 100%;
      margin-top: 10%;
      justify-content: start;
   }
   .footer .top .info p {
      font-size: 16px;
      font-weight: 300;
   }
   .footer .bottom {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 3%;
      height: 10vh;
   }
   .footer .bottom .links {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      gap: 10%;
   }
   .footer .bottom .links a {
      text-decoration: none;
      color: #fffcff;
      font-size: 16px;
      margin: 0;
   }
   .footer .bottom p {
      font-size: 16px;
      color: #fffcff;
      margin: 0;
   }
}

/* whoarewe */
#whoarewe {
   padding-top: 5%;
}
#mini-title {
   font-size: 18px;
   background-color: #f2f2f7;
   color: #494947;
   padding: 10px 14px;
   width: fit-content;
   border-radius: 15px;
   font-weight: 400;
   line-height: 21.6px;
   margin-bottom: 2%;
}
.whoarewe {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   gap: 5%;
}
.whoarewe #title {
   width: 40%;
}
.p {
   width: 53%;
   display: flex;
   flex-direction: column;
}
.p p {
   font-size: 18px;
   color: #494947;
   margin: 2% 0;
}
@media screen and (max-width: 768px) {
   #whoarewe {
      padding-top: 10%;
   }
   #mini-title {
      font-size: 14px;
      line-height: 15.6px;
   }
   .whoarewe {
      flex-direction: column;
   }
   .whoarewe #title {
      width: 100%;
   }
   .p {
      width: 100%;
   }
   .p p {
      font-size: 14px;
      line-height: 17.8px;
      margin: 2% 0;
   }
}
/* mission */
#mission {
   padding-top: 5%;
}
.mission {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}
@media screen and (max-width: 768px) {
   #mission {
      padding-top: 10%;
   }
   .mission {
      flex-direction: column;
   }
}

/* values */
#values {
   padding-top: 5%;
}
.values {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 130vh;
}
.values .item {
   background-color: #f2f2f7;
   border-radius: 50px;
   padding: 5%;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
}
.values .item h1,
p {
   margin: 0;
}
.values .item p {
   width: 53.5%;
   display: flex;
   justify-content: end;
   font-size: 18px;
   color: #494947;
}

@media screen and (max-width: 768px) {
   #values {
      padding-top: 10%;
   }
   .values {
      max-height: 100vh;
      height: 80vh;
      gap: 15px;
   }
   .values .item {
      border-radius: 20px;
      flex-direction: column;
      align-items: flex-start;
      padding: 5% 8%;
   }
   .values .item h1,
   p {
      margin: 0;
   }
   .values h1 {
      font-size: 24px;
   }
   .values .item p {
      width: 100%;
      font-size: 14px;
   }
}

/* sight */
#sight {
   padding-top: 10%;
}
.sight {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}
@media screen and (max-width: 768px) {
   #sight {
      padding-top: 10%;
   }
   .sight {
      flex-direction: column;
   }
}

/* carousel */
#carousel {
   padding-top: 5%;
}
.carousel {
   width: 100%;
   position: relative;
}
.carousel img {
   width: 100%;
   object-fit: cover;
   transition: opacity 0.5s ease-in-out;
   cursor: pointer;
   transition: 0.3s;
}
.carousel img:hover {
   transform: scale(1.005);
}
.carousel-image.fade {
   opacity: 0;
}
@media screen and (max-width: 768px) {
   #carousel {
      padding-top: 10%;
   }
   .carousel img {
      object-fit: cover;
      height: 100%;
      border-radius: 20px;
   }
}

/* join us */
#join-us {
   padding-top: 5%;
}
.join-us {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
}
@media screen and (max-width: 768px) {
   #join-us {
      padding-top: 10%;
   }
   .join-us {
      flex-direction: column;
   }
}

/* form */
#form {
   padding-top: 5%;
}
.form {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   background-color: #f2f2f7;
   border-radius: 50px;
   overflow: hidden;
}
.form .left {
   padding: 5% 0 0 5%;
   height: 100%;
   display: flex;
   flex-direction: column;
   z-index: 5;
}
.form-container {
   width: 100%;
   margin-top: 5%;
}

form {
   display: flex;
   flex-direction: column;
   gap: 16px;
   z-index: 5;
}

form input {
   width: 100%;
   max-width: 100%;
   padding: 12px 0 12px 16px;
   border: none;
   border-radius: 8px;
   font-size: 16px;
   background-color: #f9f9f9;
   color: #a0a0a0;
   font-family: "Atyp Text", sans-serif;
   font-weight: 400;
}

.inline-inputs {
   display: flex;
   gap: 5%;
}

.inline-inputs input {
   flex: 1;
   /* width: 100%; */
}

form button {
   width: 100%;
   padding: 12px 0;
   font-size: 16px;
   font-weight: 600;
   color: #fff;
   background-color: #00458a;
   border: none;
   border-radius: 8px;
   cursor: pointer;
   transition: background-color 0.3s;
   font-family: "Atyp Text", sans-serif;
   font-weight: 400;
}

form button:hover {
   background-color: #00356a;
}
.form p {
   color: #a0a0a0;
   width: 60%;
   margin: 5% 0;
}
.form-right {
   z-index: 1;
}

@media screen and (max-width: 768px) {
   #form {
      padding-top: 10%;
   }
   .form {
      position: relative;
   }
   .form .left {
      padding: 10% 5% 0 5%;
      height: 100%;
      width: 100%;
   }
   form {
      gap: 16px;
      z-index: 5;
   }

   form input {
      width: 96%;
      font-size: 14px;
   }

   .inline-inputs input {
      flex: 1;
   }

   form button {
      font-size: 14px;
      font-weight: 400;
   }
   .form p {
      width: 100%;
   }
   .form .right {
      position: absolute;
      display: flex;
      top: 0;
      right: 0;
      width: 20vw;
      height: 20vh;
   }
}

/* chronology */
/* Desktop Design */
#chronology {
   padding-top: 5%;
}
.chronology {
   display: flex;
   flex-direction: row;
   flex-wrap: wrap;
   padding: 3%;
   background-color: #f2f2f7;
   border-radius: 50px;
   justify-content: space-between;
}
.chronology .item {
   background-color: #fffcff;
   padding: 2% 3%;
   border-radius: 50px;
   width: 26%;
   aspect-ratio: 383/254;
   margin-bottom: 2%;
}
.chronology .item p {
   max-width: 100%;
   text-align-last: left;
   color: #494947;
   font-size: 20px;
}
.chronology .item #title {
   margin-bottom: 15%;
   font-size: 46px;
}
#chronology .controls {
   display: none; /* Hide dots for desktop */
}

@media (max-width: 768px) {
   .chronology {
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: hidden;
      position: relative;
      padding: 0;
      background-color: transparent;
   }

   .chronology .item {
      min-width: 100%;
      transform: translateX(-100%);
      transition: transform 0.5s ease-in-out;
      opacity: 0;
      position: absolute;
      cursor: pointer; /* Add pointer for clickability */
      background-color: #f2f2f7;
      padding: 5%;
   }
   .chronology .item p {
      width: 90%;
   }

   .chronology .item.active {
      transform: translateX(0);
      opacity: 1;
      position: relative;
   }

   /* Show dots for mobile */
   #chronology .controls {
      display: flex;
      justify-content: center;
      gap: 10px;
      margin-top: 20px;
      position: relative;
   }

   .control-dot {
      width: 5px;
      height: 5px;
      background-color: transparent;
      border-radius: 50%;
      cursor: pointer;
      transition: background-color 0.3s ease;
      border: 1px solid #00458a;
   }

   .control-dot.active {
      background-color: #00458a;
   }

   .control-dot:hover {
      background-color: #00356a;
   }
}

/* activity text */
#activity-text {
   padding-top: 5%;
}
#activity-text #title {
   font-size: 48px;
}
@media screen and (max-width: 768px) {
   #activity-text {
      padding-top: 10%;
   }
   #activity-text #title {
      font-size: 24px;
   }
}

/* services */
#services {
   padding-top: 5%;
}
.services {
   display: flex;
   flex-direction: column;
}
.services .item {
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   margin-bottom: 2%;
   gap: 2%;
}
.services .item .left {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   height: 30vh;
   padding: 2%;
   background-color: #f2f2f7;
   border-radius: 50px;
   min-width: 20%;
}
.services .item .right {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   height: 30vh;
   padding: 2%;
   background-color: #f2f2f7;
   border-radius: 50px;
}
.services .item p {
   color: #494947;
}
.services .item .right #title {
   width: 100%;
   font-size: 40px;
}
.services .item img {
   width: 197px;
}

@media screen and (max-width: 768px) {
   #services {
      padding-top: 15%;
   }
   .services .item .left {
      background-color: transparent;
      height: fit-content;
      margin: 5% 0 10%;
   }
   .services .item .right {
      background-color: transparent;
      height: fit-content;
   }
   .services .item {
      background-color: #f2f2f2;
      flex-direction: column;
      border-radius: 30px;
      padding: 5%;
      margin-bottom: 5%;
   }
   .services .item .right #title {
      font-size: 22px;
   }
   .services .item .left #title {
      display: none;
   }
}
